.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.App-main {
  flex-grow: 1;
  padding: 20px;
  background-color: #f0f0f0;
}

.joke-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.joke-container:hover {
  transform: translateY(-5px);
}

.category-tag {
  display: inline-block;
  background-color: #61dafb;
  color: #282c34;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8em;
  margin-bottom: 10px;
}

.rating-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.rating-button {
  background-color: #61dafb;
  border: none;
  color: #282c34;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.rating-button:hover {
  background-color: #21a1f1;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}